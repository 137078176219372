<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/mac-laptop-pc/create-part">
          <b-button variant="primary">
            <span class="align-middle">Add New Part</span>
          </b-button>
        </router-link>
      </div>
      <div>
        <router-link to="/mac-laptop-pc/deleted-parts">
          <b-button
            variant="danger"
            size="md"
          >
            <span class="align-middle">Show deleted Parts</span>
          </b-button>
        </router-link>
      </div>
    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Parts">
        <b-table
          responsive="sm"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.value }}
          </template>
          <template #cell(description)="data">
            {{ data.value }}
          </template>
          <template #cell(laptop_type_issues)="data">
            {{ arrayToString(data.value) }}
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <router-link :to="'/mac-laptop-pc/update-part/' + data.item.id">
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </router-link>
              <b-button
                variant="flat-danger"
                class="btn-icon"
                @click="deletePart(data.item)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const macLaptopPc = createNamespacedHelpers('macLaptopPc')

export default {
  data() {
    return {
      isBusy: false,
      loader: false,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'name', label: 'name' },
        { key: 'description', label: 'description' },
        { key: 'laptop_type_issues', label: 'types' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
    }
  },
  async mounted() {
    await this.getParts()
  },
  methods: {
    ...macLaptopPc.mapActions(['SHOW_ALL_PARTS', 'DELETE_PART']),

    async getParts() {
      try {
        this.isBusy = true
        this.items = await this.SHOW_ALL_PARTS()
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },

    arrayToString(array) {
      let string = ''
      if (array) {
        array.forEach(element => {
          if (element.laptop_type) {
            string += `${element.laptop_type.name}, `
          }
        })
        string = string.replace(/,\s*$/, '')
      }
      return string !== '' ? string : 'N/A'
    },

    async deletePart(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this part', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.loader = true
              const resp = await this.DELETE_PART(item.id)
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Part deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Part has been deleted successfully!',
                  },
                })
                this.getParts()
              }
              this.loader = false
            } catch (error) {
              this.loader = false
            }
          }
        })
    },
  },
}
</script>
